<template>
  <!-- 站内/外通知 -->
  <div class="container">
    <a-tabs :default-active-key="tabIndex" :animated="false" @change="callback">
      <a-tab-pane :key="0" :tab="tabs[0].name">
        <div class="main-content">
          <div class="main-title">公告栏通知</div>
          <div class="main-content-header">
            <div class="item">
              <div class="title">
                <span>公告栏通知（用户小程序）</span>
                <a-button type="link">
                  <router-link
                    :to="{
                      path: '/noticeAdd',
                      query: {
                        type: 1
                      }
                    }"
                  >
                    新增通知
                  </router-link>
                </a-button>
              </div>
            </div>
          </div>

          <my-table
            :columns="tabs[0].columns"
            :data-source="tabs[0].users"
            :pagination="tabs[0].userPagination"
            :loading="tabs[0].userLoading"
            :scroll="{ x: 1150 }"
            @change="tabs[0].userPaginationChange"
          >
            <template slot="type" slot-scope="record">
              {{ typeText[record.type] }}
            </template>
            <template slot="status" slot-scope="record">
              <a-badge
                :status="statuText[record.status].status"
                :text="statuText[record.status].text"
              />
            </template>
            <template slot="issue" slot-scope="record">
              {{ issueText[record.issue] }}
            </template>
            <template slot="startAt" slot-scope="record">
              <span v-if="record.startAt">{{ record.startAt }}</span>
              <span v-else>-</span>
            </template>
            <template slot="endAt" slot-scope="record">
              <span v-if="record.display === 2">长期</span>
              <span v-else>{{ record.endAt }}</span>
            </template>
            <template slot="action" slot-scope="record">
              <a-button type="link">
                <router-link
                  :to="{
                    path: '/noticeEdit',
                    query: { id: record.id, type: 1 }
                  }"
                >
                  编辑
                </router-link>
              </a-button>
              <a-button
                type="link"
                @click="setStatus(record.id, record.status, 1)"
              >
                <span v-if="record.status">下线</span>
                <span v-else>上线</span>
              </a-button>
              <a-dropdown placement="bottomCenter">
                <a-icon type="ellipsis" />
                <a-menu slot="overlay">
                  <a-menu-item :key="0" @click="onDel(record.id, 1)">
                    删除
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </template>
          </my-table>

          <div class="divider">
            <a-divider />
          </div>

          <div class="main-content-header">
            <div class="item">
              <div class="title">
                <span>公告栏通知（师傅小程序）</span>
                <a-button type="link">
                  <router-link
                    :to="{
                      path: '/noticeAdd',
                      query: {
                        type: 2
                      }
                    }"
                  >
                    新增通知
                  </router-link>
                </a-button>
              </div>
            </div>
          </div>

          <my-table
            :columns="tabs[0].columns"
            :data-source="tabs[0].masters"
            :pagination="tabs[0].masterPagination"
            :loading="tabs[0].masterLoading"
            :scroll="{ x: 1150 }"
            @change="tabs[0].masterPaginationChange"
          >
            <template slot="type" slot-scope="record">
              {{ typeText[record.type] }}
            </template>
            <template slot="status" slot-scope="record">
              <a-badge
                :status="statuText[record.status].status"
                :text="statuText[record.status].text"
              />
            </template>
            <template slot="issue" slot-scope="record">
              {{ issueText[record.issue] }}
            </template>
            <template slot="startAt" slot-scope="record">
              <span v-if="record.startAt">{{ record.startAt }}</span>
              <span v-else>-</span>
            </template>
            <template slot="endAt" slot-scope="record">
              <span v-if="record.display === 2">长期</span>
              <span v-else>{{ record.endAt }}</span>
            </template>
            <template slot="action" slot-scope="record">
              <a-button type="link">
                <router-link
                  :to="{
                    path: '/noticeEdit',
                    query: { id: record.id, type: 2 }
                  }"
                >
                  编辑
                </router-link>
              </a-button>
              <a-button
                type="link"
                @click="setStatus(record.id, record.status, 2)"
              >
                <span v-if="record.status">下线</span>
                <span v-else>上线</span>
              </a-button>
              <a-dropdown placement="bottomCenter">
                <a-icon type="ellipsis" />
                <a-menu slot="overlay">
                  <a-menu-item :key="0" @click="onDel(record.id, 2)">
                    删除
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </template>
          </my-table>
        </div>
      </a-tab-pane>
      <a-tab-pane :key="1" :tab="tabs[1].name">
        <div class="main-content">
          <div class="main-content-header">
            <div class="item">
              <div class="title">阿里云 - 短信通知（系统）</div>
            </div>
          </div>

          <my-table
            :columns="tabs[1].columns"
            :data-source="tabs[1].dataSource"
            :pagination="false"
            :loading="tabs[1].loading"
            :scroll="{ x: 1100, y: true }"
          >
            <template slot="type" slot-scope="record">
              <span v-if="record.type === 1">
                {{ triggerTypeText[record.type] }}
              </span>
              <span v-else>人员类型（{{ triggerTypeText[record.type] }}）</span>
            </template>
            <template slot="num" slot-scope="record">
              <span v-if="record.num">{{ record.num }}</span>
              <span v-else>-</span>
            </template>
            <template slot="status" slot-scope="record">
              <a-badge
                :status="statuOuterText[record.status].status"
                :text="statuOuterText[record.status].text"
              />
            </template>
            <template slot="action" slot-scope="record">
              <a-button type="link" @click="onChangeTrigger(record)">
                触达人员设置
              </a-button>
              <a-button
                type="link"
                @click="setOuterStatus(record.id, record.status)"
              >
                <span v-if="record.status">禁用</span>
                <span v-else>启用</span>
              </a-button>
            </template>
          </my-table>
        </div>
      </a-tab-pane>
    </a-tabs>

    <a-modal
      v-model="modalVisible"
      title="触达人员设置"
      :footer="null"
      @cancel="handleModalCancel"
    >
      <div class="modal-title">
        <span
          >当前通知人员类型：{{ triggerTypeText[modalFormTriggerType] }}</span
        >
        <a-button type="link" @click="editStatus = true">
          修改类型
        </a-button>
      </div>
      <template v-if="editStatus">
        <a-divider />
        <a-form-model ref="modalRuleForm" :model="modalForm" :rules="rules">
          <a-form-model-item label="选择人员类型" prop="type">
            <a-select v-model="modalForm.type" placeholder="请选择人员类型">
              <a-select-option
                v-for="item in modelTypes"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item>
            <a-button class="cancel-btn" @click="handleModalCancel">
              取消
            </a-button>
            <a-button
              class="submit-btn"
              type="primary"
              :loading="modalLoading"
              @click="handleModalSubmit"
            >
              确 认
            </a-button>
          </a-form-model-item>
        </a-form-model>
      </template>
    </a-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabs: [
        {
          key: 0,
          name: '站内通知',
          users: [],
          masters: [],
          columns: [
            {
              title: '序号',
              width: 70,
              customRender: (text, record, index) => index + 1
            },
            {
              title: '通知类型',
              width: 100,
              scopedSlots: { customRender: 'type' }
            },
            {
              title: '标题',
              width: 200,
              dataIndex: 'title'
            },
            {
              title: '发布方式',
              width: 150,
              scopedSlots: { customRender: 'issue' }
            },
            {
              title: '预计发布日期',
              width: 160,
              scopedSlots: { customRender: 'startAt' }
            },
            {
              title: '预计下线日期',
              width: 180,
              scopedSlots: { customRender: 'endAt' }
            },
            {
              title: '当前状态',
              scopedSlots: { customRender: 'status' }
            },
            {
              title: '操作',
              width: 180,
              fixed: 'right',
              scopedSlots: {
                customRender: 'action'
              }
            }
          ],
          userLoading: true,
          userPagination: {
            current: 1,
            total: 0,
            defaultPageSize: 3
          },
          masterLoading: true,
          masterPagination: {
            current: 1,
            total: 0,
            defaultPageSize: 3
          },
          userPaginationChange: this.handleUserPaginationChange,
          masterPaginationChange: this.handleMasterPaginationChange
        },
        {
          key: 1,
          name: '站外通知',
          dataSource: [],
          columns: [
            {
              title: '序号',
              width: 70,
              customRender: (text, record, index) => index + 1
            },
            {
              title: '短信用途',
              width: 250,
              dataIndex: 'name'
            },
            {
              title: '模版CODE',
              width: 150,
              dataIndex: 'templateCode'
            },
            {
              title: '触达方式',
              width: 200,
              scopedSlots: { customRender: 'type' }
            },
            {
              title: '人员数量',
              width: 100,
              scopedSlots: { customRender: 'num' }
            },
            {
              title: '状态',
              scopedSlots: { customRender: 'status' }
            },
            {
              title: '操作',
              width: 220,
              fixed: 'right',
              scopedSlots: {
                customRender: 'action'
              }
            }
          ],
          loading: true
        }
      ],
      tabIndex: 0,
      typeText: {
        1: '平台公告',
        2: '活动通知'
      },
      statuText: [
        {
          status: 'default',
          text: '下线'
        },
        {
          status: 'success',
          text: '上线'
        }
      ],
      issueText: {
        1: '手动发布',
        2: '系统定时发布'
      },
      displayText: {
        1: '定时显示',
        2: '长期显示'
      },
      triggerTypeText: {
        1: '指定人员',
        2: '接单师傅',
        3: '下单用户'
      },
      statuOuterText: [
        {
          status: 'default',
          text: '禁用'
        },
        {
          status: 'success',
          text: '启用'
        }
      ],
      modelTypes: [
        {
          id: 1,
          name: '指定手机号'
        },
        {
          id: 2,
          name: '接单师傅'
        },
        {
          id: 3,
          name: '下单用户'
        }
      ],
      rules: {
        type: [{ required: true, message: '请选择人员类型', trigger: 'change' }]
      },
      modalVisible: false,
      modalLoading: false,
      modalForm: {
        id: '',
        type: undefined
      },
      editStatus: false,
      modalFormTriggerType: null
    }
  },
  created() {
    this.getClientUserNoticeInside()
    this.getClientMasterNoticeInside()
  },
  methods: {
    callback(key) {
      // tab切换
      this.tabIndex = key
      if (key && !this.tabs[1].dataSource.length) {
        this.getNoticeOuter()
      }
    },
    getClientUserNoticeInside() {
      // 获取用户端站内通知列表
      const tab = this.tabs[0]
      const data = {
        page: tab.userPagination.current,
        limit: 3
      }
      this.$axios.getClientUserNoticeInside(data).then((res) => {
        const d = res.data.data
        d.data.forEach((e) => {
          e.startAt = this.setAt(e.startAt)
          e.endAt = this.setAt(e.endAt)
        })
        tab.users = d.data
        tab.userPagination.total = d.count
        tab.userLoading = false
      })
    },
    getClientMasterNoticeInside() {
      // 获取师傅端站内通知列表
      const tab = this.tabs[0]
      const data = {
        page: tab.masterPagination.current,
        limit: 3
      }
      this.$axios.getClientMasterNoticeInside(data).then((res) => {
        const d = res.data.data
        d.data.forEach((e) => {
          e.startAt = this.setAt(e.startAt)
          e.endAt = this.setAt(e.endAt)
        })
        tab.masters = d.data
        tab.masterPagination.total = d.count
        tab.masterLoading = false
      })
    },
    setAt(e) {
      // 删除日期时间的秒
      if (e) {
        return e.substring(0, e.length - 3)
      }
    },
    handleUserPaginationChange(e) {
      // 用户端分页
      const tab = this.tabs[0]
      tab.userLoading = true
      tab.userPagination.current = e.current
      this.getClientUserNoticeInside()
    },
    handleMasterPaginationChange(e) {
      // 师傅端分页
      const tab = this.tabs[0]
      tab.masterLoading = true
      tab.masterPagination.current = e.current
      this.getClientMasterNoticeInside()
    },
    setStatus(id, status, type) {
      // 设置状态
      let title = ''
      if (status === 1) {
        title = '确定设为下线？'
        status = 0
      } else {
        title = '确定设为上线？'
        status = 1
      }
      this.$confirm({
        title: title,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          const data = {
            id,
            status,
            type
          }
          this.$axios.changeNoticeInsideStatus(data).then(() => {
            this.$message.success('操作成功')
            if (type === 1) {
              this.getClientUserNoticeInside()
            } else {
              this.getClientMasterNoticeInside()
            }
          })
        }
      })
    },
    onDel(id, type) {
      // 删除
      this.$confirm({
        title: '确定删除吗？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          const data = {
            id,
            type
          }
          this.$axios.delNoticeInside(data).then(() => {
            this.$message.success('操作成功')
            if (type === 1) {
              this.tabs[0].userPagination.current = 1
              this.getClientUserNoticeInside()
            } else {
              this.tabs[0].masterPagination.current = 1
              this.getClientMasterNoticeInside()
            }
          })
        }
      })
    },
    getNoticeOuter() {
      // 获取站外通知列表
      this.$axios.getNoticeOuter().then((res) => {
        const tab = this.tabs[1]
        tab.dataSource = res.data.data
        tab.loading = false
      })
    },
    onChangeTrigger(e) {
      // 修改站外通知触发方式

      if (e.type === 1) {
        this.$router.push({
          path: '/noticeTriggerUser',
          query: {
            id: e.id,
            name: e.name
          }
        })
      } else {
        this.modalForm.id = e.id
        this.modalFormTriggerType = e.type
        this.modalVisible = true
      }
    },
    handleModalCancel() {
      this.modalVisible = false
      this.editStatus = false
      this.modalForm.type = undefined
      this.modalForm.id = null
      this.modalLoading = false
    },
    handleModalSubmit() {
      // 更改站外通知触发类型
      this.$refs.modalRuleForm.validate((valid) => {
        if (valid) {
          this.modalLoading = true
          this.$axios.changeNoticeOuterTriggerType(this.modalForm).then(() => {
            this.$message.success('操作成功')
            this.handleModalCancel()
            this.getNoticeOuter()
          })
        }
      })
    },
    setOuterStatus(id, status) {
      let title = ''
      if (status === 1) {
        title = '确定禁用？'
        status = 0
      } else {
        title = '确定启用？'
        status = 1
      }
      this.$confirm({
        title: title,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          const data = {
            id,
            status
          }
          this.$axios.changeNoticeOuterStatus(data).then(() => {
            this.$message.success('操作成功')
            this.getNoticeOuter()
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.main-content {
  overflow-x: auto;
}

.main-title {
  font-size: 18px;
  font-weight: 500;
  color: #333;
  background-color: #fff;
  padding: 32px 32px 0 32px;
}

.divider {
  background-color: #fff;
  padding: 0 32px;
}

.divider .ant-divider {
  margin: 8px 0 4px 0;
}

.modal-title {
  display: flex;
  justify-content: space-between;
}

.submit-btn {
  margin-left: 8px;
}
</style>
